/* eslint-disable react/prop-types */

import React, { useEffect, useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TextField from "@mui/material/TextField";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import styled from "@emotion/styled";
import { QUE_IMG_URL } from "../Common/Common";
import Snackbar from "@mui/material/Snackbar";
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

function ShowNewQueCreatePhoto({ openPhoto, handleSavePhoto, handleClose, localList }) {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      // const src = URL.createObjectURL(acceptedFiles[0]);
      // setFileUrl(src);
      setSendfiles(acceptedFiles[0]);
      const reader = new FileReader();
      reader.onload = function (e) {
        // console.log(e.target.result); //データURLがコンソールに表示される
        setFileUrl(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: onDrop,
    accept: "image/*",
  });
  useEffect(() => {
    const filefetch = async (url) => {
      // setFileUrl(url);
      const response = await fetch(url).then((r) => r);
      const blob = await response.blob();
      const file = new File([blob], openPhoto.seq + "@" + openPhoto.no);
      setSendfiles(file);
      const reader = new FileReader();
      reader.onload = function (e) {
        // console.log(e.target.result); //データURLがコンソールに表示される
        setFileUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    };
    handleClear();
    if (openPhoto.isopen) {
      for (let i = 0; i < localList.length; i++) {
        if (localList[i].seq == openPhoto.seq) {
          for (let j = 0; j < localList[i].list.length; j++) {
            if (localList[i].list[j].no == openPhoto.no) {
              if (localList[i].list[j].isphotouploaded == "1") {
                /* urlが""って事は、既に登録されているやつをDBから引っ張て来た場合ってこと */
                filefetch(
                  localList[i].list[j].url == ""
                    ? QUE_IMG_URL +
                        `?queid=${openPhoto.anpiid}&seq=${openPhoto.seq}&no=${
                          openPhoto.no
                        }&size=big&${Date.now().toString()}`
                    : localList[i].list[j].url
                );
              }

              if (localList[i].list[j].information != "") {
                setTextMessage(localList[i].list[j].information);
              }
              /* どちらかが既に入ってた場合は、修正orクリアできるようにしておく */
              if (localList[i].list[j].isphotouploaded == "1" || localList[i].list[j].information != "") {
                setIsVisible(true);
              }
            }
          }
        }
      }
    }
  }, [openPhoto]);
  const [fileUrl, setFileUrl] = useState(null);
  const [textmessage, setTextMessage] = useState("");
  const [sendfiles, setSendfiles] = useState(null);
  useEffect(() => {
    // setSendfiles(acceptedFiles);
    if (acceptedFiles.length > 0) {
      setIsVisible(true);
    } else if (textmessage && textmessage.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [acceptedFiles, textmessage]);

  const [isVisible, setIsVisible] = useState(false);
  const [snackopen, setSnackOpen] = useState(false);

  const handleClear = async () => {
    setSendfiles(null);
    setTextMessage("");
    setFileUrl(null);
    setIsVisible(false);
  };
  const handleSave = async () => {
    if (sendfiles) {
      handleSavePhoto(sendfiles ? "1" : "0", sendfiles, fileUrl, textmessage);
      // console.log("sendfiles", sendfiles);
      handleClose();
    } else {
      setSnackOpen(true);
    }
  };
  return (
    <Dialog open={openPhoto.isopen} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">写真選択</StyledDialogTitle>

      <DialogContent>
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="画像が選択されていません。"
        ></Snackbar>
        <div className="logoff-wrap" style={{ marginTop: "5px" }}>
          {fileUrl ? (
            <div className="drop-zone-image-div-wraper">
              <img src={fileUrl} style={{ objectFit: "contain", width: "300px", height: "200px" }} />
            </div>
          ) : (
            <div {...getRootProps({ className: "drop-zone-div-wraper" })}>
              <input {...getInputProps()} />
              <CloudUploadIcon></CloudUploadIcon>
              <p>写真をドラッグアンドドロップ</p>
              <p>または、選択してください</p>
            </div>
          )}

          <TextField
            // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            placeholder="（任意）画像の説明を記載"
            // label={isAlumni == "0" ? "本部ID・名前" : "本部ID・名前・生年月日(yyyyMMdd)"}
            variant="outlined"
            value={textmessage}
            multiline
            rows={4}
            onChange={(e) => {
              setTextMessage(e.target.value);
            }}
            style={{ marginTop: "8px", width: "100%" }}
          />

          <div className="read-member-content" style={{ marginTop: "10px" }}>
            <Button
              disabled={isVisible ? false : true}
              style={{ margin: "0 auto" }}
              variant="contained"
              color="primary"
              onClick={handleSave}
              startIcon={<SaveOutlinedIcon />}
            >
              変更
            </Button>
            <StyledButton
              disabled={isVisible ? false : true}
              style={{ margin: "0 auto" }}
              variant="contained"
              color="primary"
              //className={classes.clearButton}
              onClick={() => {
                handleClear();
                handleSavePhoto("0", "", "", "");
              }}
            >
              クリア
            </StyledButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ShowNewQueCreatePhoto;
