/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Grid from "@mui/material/Grid";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { QUE_IMG_URL } from "../Common/Common";

const StyledRoot = styled("div")`
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`;

const StyledFlexEndDiv = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

const StyledBetweenDiv = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIsPhotoDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledPhotoDiv = styled("div")`
  padding: 13px 10px 8px 12px;
  margin: 4px 4px 0px 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #c7c7d3;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
`;
const StyledPhotoImagDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 2px 2px 2px;
  border-width: 1px;
  border-style: solid;
  border-color: #c7c7d3;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
`;
const StyledSelect = styled(Select)`
  margin-top: 2px;
  margin-bottom: 10px;
`;

function ShowAnpiCreateDetail({
  listlength,
  anpidetail,
  editTitle,
  editKbn,
  editSu,
  editSeq,
  editText,
  isanpi,
  isrun, // 実施中or終了の場合true form等を触れないように
  editSeqRequired,
  setOpenPhoto,
  handleIsPhotoChange,
}) {
  useEffect(() => {
    //console.log("anpidetail2", anpidetail);
  }, []);

  const handleNoPlus = () => {
    editSu("plus", anpidetail.seq);
  };
  const handleNoMinus = () => {
    editSu("minus", anpidetail.seq);
  };
  const handleSeqPlus = () => {
    editSeq("plus");
  };
  const handleSeqMinus = () => {
    editSeq("minus");
  };

  const handleKbnChange = (event) => {
    editKbn(anpidetail.seq, event.target.value);
  };

  const handleSeqRequiredChange = (value) => {
    editSeqRequired(value, anpidetail.seq);
  };

  const handleSeqPhotoChange = (anpiid, seq, no) => {
    setOpenPhoto({
      isopen: true,
      anpiid: anpiid,
      seq: seq,
      no: no,
    });
  };

  return (
    <div>
      <StyledRoot>
        <Grid container alignItems="center">
          <Grid item xs={1} className="dragHandleSelector">
            <DragHandleIcon />
          </Grid>
          <Grid item xs={11}>
            <div>
              <TextField
                id={`outlined-multiline-static-${anpidetail.seq}`}
                label="質問"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={anpidetail.title}
                onChange={(e) => editTitle(anpidetail.seq, e.target.value)}
                margin="dense"
                style={{ width: "100%", zIndex: 0 }}
                disabled={isrun ? true : false}
              />
              <StyledBetweenDiv>
                <div>
                  <FormControl disabled={isrun ? true : false}>
                    <StyledSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={anpidetail.kbn}
                      //defaultValue={anpidetail.kbn}
                      onChange={handleKbnChange}
                    >
                      <MenuItem value={"radio"}>単一選択</MenuItem>
                      {isanpi == "0" ? <MenuItem value={"multi"}>複数選択</MenuItem> : null}
                      <MenuItem value={"text"}>フリー入力</MenuItem>
                    </StyledSelect>
                  </FormControl>
                </div>
                {/* 必須設定できるのはひとまずアンケートだけ */}
                {isanpi == "0" ? (
                  <div>
                    {anpidetail.kbn != "text" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={isrun ? true : false}
                            checked={anpidetail.isphoto === "1" ? true : false}
                            onChange={() => {
                              handleIsPhotoChange(anpidetail.seq);
                            }}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="写真付き"
                      />
                    ) : (
                      <div></div>
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isrun ? true : false}
                          checked={anpidetail.required === "1" ? true : false}
                          onChange={(e) => {
                            handleSeqRequiredChange(e.target.checked);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="必須質問"
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </StyledBetweenDiv>
              {anpidetail.kbn != "text" ? (
                anpidetail.list.map((e, index) => {
                  return (
                    <StyledIsPhotoDiv key={index}>
                      {anpidetail.isphoto == "1" ? (
                        e.isphotouploaded == "1" ? (
                          <StyledPhotoImagDiv
                            onClick={
                              isrun
                                ? () => {}
                                : () => {
                                    handleSeqPhotoChange(anpidetail.anpiid, anpidetail.seq, e.no);
                                  }
                            }
                          >
                            <img
                              src={
                                e.url == ""
                                  ? QUE_IMG_URL +
                                    `?queid=${anpidetail.anpiid}&seq=${anpidetail.seq}&no=${
                                      e.no
                                    }&size=mini&${Date.now().toString()}`
                                  : e.url
                              }
                              style={{ objectFit: "contain", width: "50px", height: "50px" }}
                            />
                          </StyledPhotoImagDiv>
                        ) : (
                          <StyledPhotoDiv
                            onClick={
                              isrun
                                ? () => {}
                                : () => {
                                    handleSeqPhotoChange(anpidetail.anpiid, anpidetail.seq, e.no);
                                  }
                            }
                          >
                            <AddPhotoAlternateOutlinedIcon></AddPhotoAlternateOutlinedIcon>
                          </StyledPhotoDiv>
                        )
                      ) : (
                        <div></div>
                      )}
                      <TextField
                        id={`outlined-multiline-static-${anpidetail.seq}-${index}`}
                        label={"選択肢" + e.no}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={e.text}
                        onChange={(ev) => editText(anpidetail.seq, e.no, ev.target.value)}
                        margin="dense"
                        style={{ width: "100%", zIndex: 0 }}
                        disabled={isrun ? true : false}
                      />
                    </StyledIsPhotoDiv>
                  );
                })
              ) : (
                <div></div>
              )}
            </div>
            {!isrun &&
              (anpidetail.kbn != "text" ? (
                <StyledFlexEndDiv>
                  <IconButton aria-label="delete" size="small" onClick={handleNoPlus}>
                    <AddBoxIcon fontSize="inherit" />
                  </IconButton>
                  {anpidetail.list.length > 2 && (
                    <IconButton aria-label="delete" size="small" onClick={handleNoMinus}>
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </StyledFlexEndDiv>
              ) : (
                <div></div>
              ))}
          </Grid>
        </Grid>
      </StyledRoot>
      {!isrun && (
        <StyledFlexEndDiv>
          {listlength == 1 || (listlength > 1 && anpidetail.seq == listlength && listlength < 20) ? (
            <IconButton aria-label="delete" size="medium" onClick={handleSeqPlus}>
              <AddBoxIcon fontSize="inherit" />
            </IconButton>
          ) : anpidetail.seq == listlength && listlength >= 20 ? (
            <div>これ以上追加できません。</div>
          ) : (
            <div></div>
          )}
          {listlength > 1 && anpidetail.seq == listlength && (
            <IconButton aria-label="delete" size="medium" onClick={handleSeqMinus}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          )}
        </StyledFlexEndDiv>
      )}
    </div>
  );
}
export default ShowAnpiCreateDetail;
