/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
// eslint-disable-next-line no-unused-vars
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { QUE_IMG_URL } from "../Common/Common";
import ShowNewQueCreatePhotoView from "../Common/ShowNewQueCreatePhotoView";
import HideImageOutlinedIcon from "@mui/icons-material/HideImageOutlined";
const StyledRoot = styled("div")`
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
`;
const StyledMemoRoot = styled("div")`
  /* border: 1px solid; */
  border-radius: 5px;
  /* padding: 5px; */
  margin-top: 5px;
  margin-bottom: 10px;
`;
const StyledButtonDiv = styled("div")`
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  gap: 5px;
`;
const StyledMemo = styled("div")`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;
const StyledTopMarginDiv = styled("div")`
  margin-top: 8px;
`;

const StyledRequiredB = styled("b")`
  font-size: 12px;
  color: red;
  padding-bottom: 3px;
  margin-left: 7px;
`;
const StyledPhotoImagDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 2px 2px 2px;
  border-width: 1px;
  border-style: solid;
  border-color: #c7c7d3;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
`;
const StyledPhotoDiv = styled("div")`
  padding: 13px 10px 8px 12px;
  margin: 4px 4px 0px 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #c7c7d3;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
`;
// eslint-disable-next-line no-unused-vars
function ShowAnpiVoteDetail({ anpidetail, inputNoValue, inputTextValue }) {
  useEffect(() => {
    //console.log("anpidetail2", anpidetail);
  }, []);
  /* 画像を開くためのステート */
  const [open, setOpen] = useState({
    isopen: false,
    id: "0",
    seq: "0",
    no: "0",
    text: "",
    isphotouploaded: "0",
  });
  const handleClose = () => {
    setOpen({ ...open, isopen: false });
  };
  const MemoShowNewQueCreatePhotoView = useMemo(() => {
    return <ShowNewQueCreatePhotoView open={open} handleClose={handleClose} />;
  }, [open]);
  return (
    <StyledTopMarginDiv>
      <Typography variant="h6" gutterBottom>
        {anpidetail.title == "メモ欄" ? (
          <StyledMemo>
            <CreateOutlinedIcon></CreateOutlinedIcon>
            <div>{anpidetail.title}</div>
            <div>(管理者にしか表示されません)</div>
          </StyledMemo>
        ) : (
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div>{anpidetail.title}</div>
            {anpidetail.required === "1" && <StyledRequiredB>＊必須項目</StyledRequiredB>}
          </div>
        )}
      </Typography>

      {anpidetail.kbn == "radio" || anpidetail.kbn == "multi" ? (
        <StyledRoot>
          {anpidetail.list.map((e, index) => {
            return (
              <StyledButtonDiv key={index}>
                {MemoShowNewQueCreatePhotoView}
                {anpidetail.isphoto == "1" ? (
                  e.isphotouploaded == "1" ? (
                    /* 画像が登録されているNoのみ画像を表示 */
                    <StyledPhotoImagDiv
                      onClick={() => {
                        setOpen({
                          isopen: true,
                          id: anpidetail.anpiid,
                          seq: anpidetail.seq,
                          no: e.no,
                          text: e.information,
                          isphotouploaded: e.isphotouploaded,
                        });
                      }}
                    >
                      <img
                        src={
                          e.url == ""
                            ? QUE_IMG_URL +
                              `?queid=${anpidetail.anpiid}&seq=${anpidetail.seq}&no=${
                                e.no
                              }&size=mini&${Date.now().toString()}`
                            : e.url
                        }
                        style={{ objectFit: "contain", width: "50px", height: "50px" }}
                      />
                    </StyledPhotoImagDiv>
                  ) : (
                    /* 画像が登録されてない場合は、空アイコンを表示しておく */
                    <StyledPhotoDiv
                      onClick={
                        /* テキスト入ってたら、テキストだけ表示できるようにしておく */
                        e.information != ""
                          ? () => {
                              setOpen({
                                isopen: true,
                                id: anpidetail.anpiid,
                                seq: anpidetail.seq,
                                no: e.no,
                                text: e.information,
                                isphotouploaded: e.isphotouploaded,
                              });
                            }
                          : () => {}
                      }
                    >
                      <HideImageOutlinedIcon></HideImageOutlinedIcon>
                    </StyledPhotoDiv>
                  )
                ) : (
                  <div></div>
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    inputNoValue(anpidetail.seq, e.no, e.value);
                  }}
                  fullWidth
                  color={e.value == "1" ? "primary" : "default"}
                >
                  {e.text}
                </Button>
              </StyledButtonDiv>
            );
          })}
        </StyledRoot>
      ) : anpidetail.kbn == "text" ? (
        <StyledRoot>
          <TextField
            id={`outlined-multiline-static-${anpidetail.seq}`}
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            minRows={3}
            variant="outlined"
            value={anpidetail.value}
            onChange={(ev) => inputTextValue(anpidetail.seq, ev.target.value)}
            margin="dense"
            style={{ width: "100%" }}
          />
        </StyledRoot>
      ) : (
        <StyledMemoRoot>
          <TextField
            id={`outlined-multiline-static-${anpidetail.seq}`}
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            minRows={3}
            variant="outlined"
            value={anpidetail.value}
            onChange={(ev) => inputTextValue(ev.target.value)}
            margin="dense"
            style={{ width: "100%" }}
          />
        </StyledMemoRoot>
      )}
    </StyledTopMarginDiv>
  );
}
export default ShowAnpiVoteDetail;
