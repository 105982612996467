/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "./ShowUserInfo.css";
import React from "react";
import styled from "@emotion/styled";
import { QUE_IMG_URL } from "../Common/Common";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Linkify from "react-linkify";
const StyledDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  // margin-bottom: 10px;
`;
function ShowNewQueCreatePhotoView({ open, handleClose }) {
  return (
    <Dialog open={open.isopen} onClose={handleClose} maxWidth={"lg"} scroll={"paper"}>
      <DialogContent sx={{ padding: "0px", textAlign: "center" }}>
        <StyledDiv>
          {open.isphotouploaded == "1" ? (
            <img
              src={QUE_IMG_URL + `?queid=${open.id}&seq=${open.seq}&no=${open.no}&size=big&${Date.now().toString()}`}
              alt=""
              className="show-image"
            />
          ) : (
            <></>
          )}

          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target={`blank${open.id}`} href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {open.text}
            {open.text && <div style={{ marginBottom: "5px" }}></div>}
          </Linkify>
        </StyledDiv>
      </DialogContent>
    </Dialog>
  );
}
export default ShowNewQueCreatePhotoView;
